import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import './App.css';
import './fonts.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Sidebar from './components/sidebar/Sidebar';
import Chatbot from './components/chatbot/Chatbot';
import ChatbotIcon from './components/chatbot/ChatbotIcon';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Home from './pages/Home';
import Autobiography from './pages/Autobiography';
import Hobbies from './pages/Hobbies';
import Artifacts_And_Work from './pages/Artifacts_And_Work';
import Writings from './pages/Writings';
import Writing_TheRoughDraftOfTheWebsite from './pages/Writing_TheRoughDraftOfTheWebsite';



function App() {

  AOS.init({
    once: true,
    disable: window.innerWidth < 768,
    duration: 700,
    easing: "ease-out-cubic",
  });

  const [chatbotState, setChatbotState] = useState(false);

  function toggleChatbot() {
    setChatbotState(!chatbotState);
  }

  return (
    <Router>
      <div className="sonic-beige fit-content">
        <div className="sidebar-layout d-flex">
          { /* Sidebar */}
          <div data-aos="fade-right" data-aos-delay="1500" className="App-sidebar d-flex flex-column">
            
            <div className='m-4'></div>
            <div className='App-sidebar-stuff d-flex flex-column align-items-center'>
              <p className="headline-4-large">
                  MA
              </p>
              <div className='d-flex flex-column flex-sm-row justify-content-between gap-2 mb-2'>
                  <a href='https://www.linkedin.com/in/michael-ani-5a1599249/' target='_blank' className='social-media-icon no-decoration'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="30px" height="30px"><path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56c3.97,0,7.19,2.73,7.19,8.26V39z"/></svg>
                  </a>

                  <a href='https://github.com/Smashthehedgehog' target='_blank' className='social-media-icon no-decoration'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="30px" height="30px"><path d="M17.791,46.836C18.502,46.53,19,45.823,19,45v-5.4c0-0.197,0.016-0.402,0.041-0.61C19.027,38.994,19.014,38.997,19,39c0,0-3,0-3.6,0c-1.5,0-2.8-0.6-3.4-1.8c-0.7-1.3-1-3.5-2.8-4.7C8.9,32.3,9.1,32,9.7,32c0.6,0.1,1.9,0.9,2.7,2c0.9,1.1,1.8,2,3.4,2c2.487,0,3.82-0.125,4.622-0.555C21.356,34.056,22.649,33,24,33v-0.025c-5.668-0.182-9.289-2.066-10.975-4.975c-3.665,0.042-6.856,0.405-8.677,0.707c-0.058-0.327-0.108-0.656-0.151-0.987c1.797-0.296,4.843-0.647,8.345-0.714c-0.112-0.276-0.209-0.559-0.291-0.849c-3.511-0.178-6.541-0.039-8.187,0.097c-0.02-0.332-0.047-0.663-0.051-0.999c1.649-0.135,4.597-0.27,8.018-0.111c-0.079-0.5-0.13-1.011-0.13-1.543c0-1.7,0.6-3.5,1.7-5c-0.5-1.7-1.2-5.3,0.2-6.6c2.7,0,4.6,1.3,5.5,2.1C21,13.4,22.9,13,25,13s4,0.4,5.6,1.1c0.9-0.8,2.8-2.1,5.5-2.1c1.5,1.4,0.7,5,0.2,6.6c1.1,1.5,1.7,3.2,1.6,5c0,0.484-0.045,0.951-0.11,1.409c3.499-0.172,6.527-0.034,8.204,0.102c-0.002,0.337-0.033,0.666-0.051,0.999c-1.671-0.138-4.775-0.28-8.359-0.089c-0.089,0.336-0.197,0.663-0.325,0.98c3.546,0.046,6.665,0.389,8.548,0.689c-0.043,0.332-0.093,0.661-0.151,0.987c-1.912-0.306-5.171-0.664-8.879-0.682C35.112,30.873,31.557,32.75,26,32.969V33c2.6,0,5,3.9,5,6.6V45c0,0.823,0.498,1.53,1.209,1.836C41.37,43.804,48,35.164,48,25C48,12.318,37.683,2,25,2S2,12.318,2,25C2,35.164,8.63,43.804,17.791,46.836z"/></svg>
                  </a>

                  <a href="mailto:mikeani482@gmail.com" className='social-media-icon no-decoration'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 96" width="30px" height="30px"><path d="M0 11.283V8a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8v3.283l-64 40zm66.12 48.11a4.004 4.004 0 0 1-4.24 0L0 20.717V88a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8V20.717z"></path></svg>
                  </a>

              </div>

              <div className='App-sidebar-horizontal-line mb-3 mt-1'></div>

              
              {/* Input Sidebar React Application Here */}
              <Sidebar />


              
            </div>
            <div className='m-2'></div>
          </div>

          { /* Content */}
          <div className="App-content d-flex flex-column">
            {/* Main content */}
            <div className='m-4'></div>
            <Routes>
                <Route path={"/Autobiography"} element={<Autobiography />} />
                <Route path={"/Hobbies"} element={<Hobbies />} />
                <Route path={"/Artifacts_And_Work"} element={<Artifacts_And_Work />} />
                <Route path={"/Writings"} element={<Writings />} />
                <Route path={"/Writings/the-rough-draft-of-the-website"} element={<Writing_TheRoughDraftOfTheWebsite />} />
                <Route path={"/"} element={<Home />} />
                <Route path={"/my-app"} element={<Home />} />
            </Routes>

          </div>
        </div>
        < Chatbot chatbotState={chatbotState}/>
        < ChatbotIcon chatbotState={chatbotState} toggleChatbot={toggleChatbot}/>
      </div>
    </Router>
  );
}

export default App;
